import axios from '../../utils/axios'
import * as url from './urlConfig'
//用户登录接口
export const userLogin = {
    login(param) {
      return axios.post(url.login, param)
        .then((response) => {
          let data = response;
          if (data.status === 200) {
            return data;
          }else{
            console.log("api error");
          }
         
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    logout(param) {
      return axios.post(url.logout, param)
        .then((response) => {
          let data = response;
          if (data.code === 1) {
            return data;
          }else{
            console.log("api error");
          }
         
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }