import React, { PureComponent } from 'react';
import {Button,Icon,message,Form,Input,Radio,Select} from 'antd';
import {connect} from 'react-redux';
import {withRouter } from 'react-router-dom'
import * as Actions from '../../store/action'
import {user} from './service'
import './index.less'
import {FormattedMessage} from 'react-intl';
import { getLangMessage, setCookie } from '../../utils/index'
const { TextArea } = Input;
const { Option } = Select;
class Header extends PureComponent{
    constructor(props){
        super(props);
        this.state= {
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillMount(){
    }
    handleChange(value){
        this.props.dispatch(Actions.langChange(value));
        // localStorage.setItem("lang", value);
        setCookie("think_var", value);
    }
    logout= ()=> {
        user.logout({}).then(res => {
            if(res.data.Code === "Success"){
                message.success(getLangMessage("logout_success_text"));
               this.props.dispatch(Actions.authChange(true));
                setTimeout(()=>{
                    this.props.history.push({pathname:"/"})
                },1000)
              }else{
                message.error(res.data.Message);
              }
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            user.feedback(values).then(res => {
                if(res.data.Code === "Success"){
                  if(res.data.Data.status === 1){
                    message.success(getLangMessage("feedback_success_text"));
                    this.props.form.resetFields();
                    this.props.dispatch(Actions.feedbackChange(false))
                  }else{
                    message.error(res.data.Message);
                  }
                }else{
                    message.error(res.data.Message);
                  }
            })
          }
        });
      };
      feedChange = (e) =>  {
          e.preventDefault();
        e.stopPropagation();
          const {feedbackStatus} = this.props.state;
          this.props.dispatch(Actions.feedbackChange(!feedbackStatus))
      }
    render(){
        // const {address} = this.state;
        const { getFieldDecorator } = this.props.form;
        const {host,feedbackStatus} = this.props.state;
        return(
            <React.Fragment>
                <div className="header">
                <div className="title"><span></span>
                {this.props.state.customName?<React.Fragment>
                    {this.props.state.customName}
                </React.Fragment>:<React.Fragment>
                {this.props.state.adminName}<FormattedMessage id="site_name"></FormattedMessage>
                </React.Fragment>}
               
                </div>
                <div className="language">
                    {this.props.state.language==="zh-cn"?'简体中文': 'English'}
                    <span className="lang_icon"></span>
                    <Select value={this.props.state.language} style={{ width: 142,height:60,cursor:'pointer',opacity:0,position:'absolute' }} onChange={this.handleChange}>
                        <Option value="zh-cn">简体中文</Option>
                        <Option value="en-us">English</Option>   
                    </Select>
                </div>
               
                <div className={feedbackStatus?'feedback active':'feedback'} >
                
                   <span onClick={this.feedChange}> <Icon type="edit"/><FormattedMessage id="feedback"></FormattedMessage></span>
                    {feedbackStatus?<div className="feedback_content">
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} onSubmit={this.handleSubmit}>
                    <Form.Item label={getLangMessage("feedback_type")}>
                        {getFieldDecorator('type', {
                            initialValue: 1,
                            rules: [{ required: true, message: 'Please input your note!' }],
                        })(<Radio.Group>
                            <Radio value={1}>BUG</Radio>
                            <Radio value={2}><FormattedMessage id="feedback_type_item1"></FormattedMessage></Radio>
                            <Radio value={3}><FormattedMessage id="feedback_type_item2"></FormattedMessage></Radio>
                          </Radio.Group>)}
                        </Form.Item>
                        <Form.Item label={getLangMessage("feedback_content")}>
                        {getFieldDecorator('content', {
                            initialValue: "",
                            rules: [{ required: true, message: getLangMessage("feedback_content_valid") }],
                        })(<TextArea autoComplete="off" style={{width:'280px'}} rows={4} placeholder={getLangMessage("feedback_content_placeholder")} />)}
                        </Form.Item>
                        <Form.Item label={getLangMessage("feedback_realname")}>
                        {getFieldDecorator('contact_name', {
                            initialValue: "",
                            rules: [],
                        })(<Input autoComplete="off" style={{width:'280px'}} placeholder={getLangMessage("feedback_realname_placeholder")} />)}
                        </Form.Item>
                        <Form.Item label={getLangMessage("feedback_phone")}>
                        {getFieldDecorator('contact_tel', {
                            initialValue: "",
                            rules: [],
                        })(<Input autoComplete="off" style={{width:'280px'}} placeholder={getLangMessage("feedback_phone_placeholder")} />)}
                        </Form.Item>
                        <Form.Item label={getLangMessage("feedback_email")}>
                        {getFieldDecorator('contact_email', {
                            initialValue: "",
                            rules: [],
                        })(<Input autoComplete="off" style={{width:'280px'}} placeholder={getLangMessage("feedback_email_placeholder")} />)}
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                        <Button type="primary" htmlType="submit">
                            <FormattedMessage id="submit"></FormattedMessage>
                        </Button>
                        </Form.Item>
                    </Form>
                    </div>: null}
                </div>
                {/* <div className="logout">
                    <Icon type="logout"/>
                    <Button type="primary" onClick={this.logout} >退出</Button>
                </div> */}
            </div>
            <p className="address"><FormattedMessage id="server_name"></FormattedMessage>: <span>{host}</span></p>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state,ownProps) {
    return {
        state: state
    }
}


export default Form.create()(connect(mapStateToProps)(withRouter(Header)));