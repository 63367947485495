import React from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../store/action';
import {
  Modal,
  Button,
  Input,
  Table,
  Icon,
  Form,
  message,
  Menu,
  Dropdown,
  Upload,
  Radio,
  Checkbox,
} from 'antd';
import './index.less';

// 异步组件
import AsyncComponent from './../../components/asyncComponent.js';

import {
  filterTypeFile,
  filterTypeFileTwo,
  filterZipRar,
  getQueryUrl,
  filterImg,
  filterUnit,
  getLangMessage,
  setCookie,
} from '../../utils/index';

import {
  compareSizeDown,
  compareSizeUp,
  compareTimeDown,
  compareTimeUp,
  compareNameDown,
  compareNameUp,
} from '../../utils/sort';

import { home } from './service';
import { FormattedMessage } from 'react-intl';

const DeleteModal = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ './../../components/DeleteModal/index')
); //删除弹框

const RightKey = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ './../../components/RightKey/index')
); //右键

const UnpackModal = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ '../../components/UnpackModal')
); //解压

const PackModal = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ '../../components/packModal')
); //压缩

const RenameModal = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ '../../components/renameModal')
); //重命名

const RemoteDirectory = AsyncComponent(() =>
  import(
    /* webpackChunkName: "Home" */ './../../components/RemoteDirectory/index'
  )
); //远程选取目录

const NewFile = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ '../../components/NewFile')
); //新建文件-文件夹

const SelectDirectory = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ '../../components/SelectDirectory')
); //选择目录

const EditModal = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ '../../components/EditModal')
); //编辑

const PreviewImg = AsyncComponent(() =>
  import(/* webpackChunkName: "Home" */ '../../components/PreviewImg')
); //图片预览插件

const AuthPermission = AsyncComponent(() =>
  import(
    /* webpackChunkName: "Home" */ './../../components/AuthPermission/index'
  )
); //目录权限

const { Search } = Input;
const { confirm } = Modal;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.remoteDirectory = React.createRef();
    this.state = {
      savePath: '', //选择目录树存储路径
      unpackName: '',

      //删除弹框
      deleteVisible: false,
      deleteLoading: false,
      // 右键操作
      rightStyle: {
        top: 0,
        left: 0,
      },
      rightKey_directory: false,
      // 解压弹窗
      uppackVisble: false,
      unpackLoading: false,
      // 压缩弹窗
      packVisble: false,
      packLoading: false,
      // 下载存储弹窗
      renameVisble: false,
      renameLoading: false,
      // 选择远程路径弹窗
      remoteDirectoryVisble: false,

      // 新建文件/文件夹
      newFileVisble: false,
      createKey: 1, //1-文件夹，2-文件, 3-弹窗新建文件夹
      newFileloading: false, //新建按钮加载
      newFilePath: '',
      newFileName: getLangMessage('folder_name'),

      //移动复制
      moveAndCopy: 1, //1-移动，2-复制
      selectDirVisble: false,
      confirmLoading: false,
      selectDirTitle: getLangMessage('remove_folder'),

      // 重命名
      currentEditName: '',

      // 列表展现形式
      listType: '1',

      // 编辑
      editVisble: false,
      editInitVal: '', //编辑内容
      code: '', //字符编码
      editLoading: false,

      // 图片预览
      previewVisble: false,
      imgInfo: null,

      // 排序事件
      isSizeSort: '', // true-升序，false-降序
      isTimeSort: '', // true-升序，false-降序
      isNameSort: false,

      // 搜索
      keyword: '',

      // 快捷键的复制粘贴键
      shortCutsKey: 1, //1-ctrl+x移动,2-ctrl+c复制

      // 区域多选
      startX: 0,
      startY: 0,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      let searchText = getQueryUrl('searchText');
      if (!searchText) {
        this.setState({
          isSizeSort: '',
          isTimeSort: '',
          isNameSort: false,
        });
        this.props.dispatch(Actions.getDirectory());
      } else {
        this.setState({
          isSizeSort: '',
          isTimeSort: '',
          isNameSort: false,
        });
        this.props.dispatch(Actions.getSearchDirectory());
      }
      const { dataList } = this.props.state;
      let newAction = [];
      dataList.forEach(() => {
        newAction.push(false);
      });
      this.setState({
        action: newAction,
      });
    }
  }

  componentWillMount() {
    let searchText = getQueryUrl('searchText');
    let sitename = getQueryUrl('siteName');
    let customName = getQueryUrl('customName');

    let host = getQueryUrl('host');
    let lang = getQueryUrl('lang');
    if (lang) {
      setCookie('think_var', lang);
      this.props.dispatch(Actions.langChange(lang));
    }
    if (sitename) {
      let newName = sitename + '';
      localStorage.setItem('sitename', sitename);
      this.props.dispatch(Actions.adminNameChange(newName));
    }
    if (customName) {
      localStorage.setItem('customName', customName);
      this.props.dispatch(Actions.customNameChange(customName));
    } else {
      localStorage.removeItem('customName');
      this.props.dispatch(Actions.customNameChange(''));
    }
    if (host) {
      localStorage.setItem('host', host);
      this.props.dispatch(Actions.hostChange(host));
    }
    if (!searchText) {
      this.props.dispatch(Actions.getDirectory());
    } else {
      this.props.dispatch(Actions.getSearchDirectory());
    }
  }
  componentDidMount() {
    document.documentElement.addEventListener('click', e => {
      // e.preventDefault();
      // e.stopPropagation();

      setTimeout(() => {
        this.props.dispatch(Actions.closeRightKey());
        // this.props.dispatch(Actions.feedbackChange(false))
      }, 1);
    });

    // 监听组合快捷键的使用
    document.documentElement.addEventListener('keydown', e => {
      // this.onSelectChange(["/logfiles"])
      let event = e || window.e;

      /*ctrl+x */
      if (event.keyCode === 88 && event.ctrlKey) {
        const { selectedRowKeys, dataList } = this.props.state;
        if (selectedRowKeys.length === 0) {
          message.warn(getLangMessage('cut_choose_text'));
          return;
        } else if (selectedRowKeys.length > 1) {
          message.warn(getLangMessage('cut_choose_valid_text'));
          return;
        }
        const saveContent = this.filterDirectory(selectedRowKeys[0], dataList);

        this.props.dispatch(Actions.shortCutsChange(saveContent));
        this.setState({
          shortCutsKey: 1,
        });
        message.success(getLangMessage('cut_choose_success_text'));
      }

      /*ctrl+C */
      if (event.keyCode === 67 && event.ctrlKey) {
        const { selectedRowKeys, dataList } = this.props.state;
        if (selectedRowKeys.length === 0) {
          message.warn(getLangMessage('copy_choose_text'));
          return;
        } else if (selectedRowKeys.length > 1) {
          message.warn(getLangMessage('copy_choose_valid_text'));
          return;
        }
        const saveContent = this.filterDirectory(selectedRowKeys[0], dataList);

        this.props.dispatch(Actions.shortCutsChange(saveContent));
        this.setState({
          shortCutsKey: 2,
        });
        message.success(getLangMessage('copy_choose_success_text'));
      }

      /* ctrl+v*/
      if (event.keyCode === 86 && event.ctrlKey) {
        const { shortCutsObj } = this.props.state;
        const { shortCutsKey } = this.state;
        if (shortCutsObj === null) {
          message.warn(getLangMessage('paste_choose_text'));
          return;
        }
        let title =
          shortCutsKey === 1
            ? getLangMessage('paste_remove_text')
            : getLangMessage('paste_copy_text');
        let that = this;
        confirm({
          title: title,
          onOk() {
            that.pasterOk();
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    });
    // let flag = false;

    // document.documentElement.addEventListener('mousedown', e => {
    //   flag = true;
    //   try {
    //     let event = e || window.e;
    //     console.log(event);
    //     this.setState({
    //       startX: event.clientX,
    //       startY: event.clientY,
    //     });
    //     let selCanvas = document.createElement('div');
    //     selCanvas.setAttribute('id', 'maskDiv');
    //     selCanvas.style.cssText =
    //       'position:fixed;background:rgba(0,170,255,0.2);animation:1s all linear;';
    //     document.body.appendChild(selCanvas);
    //   } catch (error) {}
    // });

    // document.documentElement.addEventListener('mousemove', e => {
    //   if (flag) {
    //     try {
    //       let event = e || window.e;
    //       const { dataList } = this.props.state;
    //       const { listType } = this.state;
    //       let clientX = event.clientX;
    //       let clientY = event.clientY;
    //       let scrollTop = this.getScrollTop();
    //       let selectArr = [];

    //       if (listType == 1) {
    //         let tabelList = document.getElementById('tableList');

    //         let tBody = tabelList.getElementsByTagName('tbody')[0];
    //         let trList = tBody.getElementsByTagName('tr');
    //         for (let i = 0; i < trList.length; i++) {
    //           let top = parseInt(trList[i].offsetTop + 251 - scrollTop + 27);
    //           if (event.clientY - this.state.startY > 0) {
    //             if (top > this.state.startY && top < clientY) {
    //               selectArr.push(dataList[i].address);
    //             }
    //           } else {
    //             if (top > clientY && top < this.state.startY) {
    //               selectArr.push(dataList[i].address);
    //             }
    //           }
    //         }
    //       }

    //       let selCanvas = document.getElementById('maskDiv');
    //       if (selCanvas) {
    //         selCanvas.style.width =
    //           Math.abs(event.clientX - this.state.startX) + 'px';
    //         selCanvas.style.height =
    //           Math.abs(event.clientY - this.state.startY) + 'px';

    //         if (event.clientX - this.state.startX > 0) {
    //           selCanvas.style.left = this.state.startX + 'px';
    //           selCanvas.style.top = this.state.startY + 'px';
    //         } else {
    //           selCanvas.style.left = event.clientX + 'px';
    //           selCanvas.style.top = event.clientY + 'px';
    //         }
    //       }
    //       // this.onSelectChange(selectArr);
    //     } catch (error) {}
    //   }
    // });

    // document.documentElement.addEventListener('mouseup', e => {
    //   try {
    //     if (document.getElementById('maskDiv')) {
    //       document.getElementById('maskDiv').remove();
    //     }
    //     flag = false;
    //   } catch (error) {}
    // });
  }

  getScrollTop = () => {
    // 获取滚动条的高度
    var scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
      scrollTop = document.body.scrollTop;
    }
    return scrollTop;
  };
  // 快捷键粘贴事件
  pasterOk = () => {
    const path = getQueryUrl('path');
    const { shortCutsKey } = this.state;
    const { shortCutsObj } = this.props.state;
    let isDirectory = shortCutsObj.isDirectory;
    let name = shortCutsObj.name;
    let distination = '';
    if (path == shortCutsObj.address) {
      message.warn(getLangMessage('remove_valid_test'));
      return;
    }
    this.setState({
      confirmLoading: true,
    });
    if (path == '/') {
      distination = path + `${name}`;
    } else {
      distination = path + `/${name}`;
    }

    if (shortCutsKey === 1) {
      let params = {
        source: shortCutsObj.address,
        is_directory: isDirectory,
        destination: distination,
      };
      home.cut(params).then(res => {
        let Data = res.data;
        if (Data.Code === 'Success') {
          if (Data.Data.status === 1) {
            message.success(getLangMessage('remove_success_text'));
            this.setState({
              moveAndCopy: 1,
              selectDirVisble: false,

              savePath: '',
              isSizeSort: '',
              isTimeSort: '',
              isNameSort: false,
            });
            let searchText = getQueryUrl('searchText');
            if (!searchText) {
              this.props.dispatch(Actions.getDirectory());
              this.props.dispatch(Actions.shortCutsChange(null));
            } else {
              this.props.dispatch(Actions.getSearchDirectory());
              this.props.dispatch(Actions.shortCutsChange(null));
            }
          } else {
            message.error(Data.Message);
          }
        } else {
          message.error(Data.Message);
        }
        this.setState({
          confirmLoading: false,
        });
      });
    } else {
      let params = {
        source: shortCutsObj.address,
        is_directory: isDirectory,
        destination: distination,
      };
      home.copy(params).then(res => {
        let Data = res.data;
        if (Data.Code === 'Success') {
          if (Data.Data.status === 1) {
            message.success(getLangMessage('copy_success_text'));
            this.setState({
              moveAndCopy: 1,
              selectDirVisble: false,

              savePath: '',
              isSizeSort: '',
              isTimeSort: '',
              isNameSort: false,
            });
            let searchText = getQueryUrl('searchText');
            if (!searchText) {
              this.props.dispatch(Actions.getDirectory());
              this.props.dispatch(Actions.shortCutsChange(null));
            } else {
              this.props.dispatch(Actions.getSearchDirectory());
              this.props.dispatch(Actions.shortCutsChange(null));
            }
          } else {
            message.error(Data.Message);
          }
        } else {
          message.error(Data.Message);
        }
        this.setState({
          confirmLoading: false,
        });
      });
    }
  };
  // 表格的选中事件
  onSelectChange = selectedRowKeys => {
    const { dataList } = this.props.state;
    let newData = dataList.concat([]);
    newData.forEach(item => {
      item.checkAction = false;
      if (selectedRowKeys.indexOf(item.address) > -1) {
        item.checkAction = true;
      }
    });
    let isAllSelect = false;
    if (selectedRowKeys.length === dataList.length) {
      isAllSelect = true;
    }
    this.props.dispatch(Actions.allselectChange(isAllSelect));
    this.props.dispatch(Actions.datalistChange(newData));
    this.props.dispatch(Actions.changeSelectKeys(selectedRowKeys));
  };
  // 删除弹窗的确认事件
  deleteOk = () => {
    this.setState({
      deleteLoading: true,
    });
    const { selectedRowKeys, dataList } = this.props.state;
    let params = {
      paths_and_types: {
        paths_and_types: [],
      },
    };
    let selectKey = selectedRowKeys.concat([]);

    selectKey.forEach(item => {
      let isDirectory = this.filterDirectory(item, dataList).isDirectory;
      params['paths_and_types']['paths_and_types'].push([item, isDirectory]);
    });
    home.deleteMultiple(params).then(res => {
      this.setState({
        deleteLoading: false,
      });
      if (res.data.Code === 'Success') {
        if (res.data.Data.status === 1) {
          message.success(getLangMessage('delete_success_text'));
          this.setState({
            deleteVisible: false,
            previewVisble: false,
            imgInfo: null,
            isSizeSort: '',
            isTimeSort: '',
            isNameSort: false,
          });
          let searchText = getQueryUrl('searchText');
          if (!searchText) {
            this.props.dispatch(Actions.getDirectory());
          } else {
            this.props.dispatch(Actions.getSearchDirectory());
          }
        } else {
          message.error(getLangMessage('delete_fail_text'));
        }
      } else {
        message.error(getLangMessage('delete_fail_text'));
      }
    });
  };
  // 删除弹窗的取消事件
  deleteCancel = () => {
    this.setState({
      deleteVisible: false,
    });
  };
  deleteFile = e => {
    e.preventDefault();
    // 删除文件
    this.setState({
      deleteVisible: true,
    });
  };
  OpenFile = () => {
    // 打开文件
    // 查看文件内容
    const { selectedRowKeys, dataList } = this.props.state;

    let record = this.filterDirectory(selectedRowKeys[0], dataList);
    this.props.dispatch(Actions.switchLoading(true));
    this.props.dispatch(Actions.saveEditContent(record));
    let remote_path = record.address;
    home.getFileContents({ remote_path }).then(res => {
      this.props.dispatch(Actions.switchLoading(false));
      if (res.data.Code === 'Success') {
        if (res.data.Data.status === 1) {
          if (filterImg(record.name)) {
            this.setState({
              previewVisble: true,
              imgInfo: {
                src: res.data.Data.content,
                name: record.name,
                address: record.address,
                size: res.data.Data.size,
                width: res.data.Data.width,
                height: res.data.Data.height,
                date: res.data.Data.modificationDate,
              },
            });
          } else {
            this.setState({
              editInitVal: res.data.Data.content,
              editVisble: true,
              code: res.data.Data.encode,
            });
          }
        } else {
          message.error(res.data.Message);
        }
      } else {
        message.error(res.data.Message);
      }
    });
  };
  downloadFile = () => {
    //下载文件

    const { selectedRowKeys, dataList } = this.props.state;
    let size = '';
    dataList.forEach(item => {
      if (item.address === selectedRowKeys[0]) {
        size = item.size;
      }
    });
    if (parseInt(filterUnit(size)) > 100 * 1024 * 1024) {
      message.warn(getLangMessage('download_file_limit'));
      return;
    }
    this.props.dispatch(Actions.switchLoading(true));
    let path = getQueryUrl('path');
    let params = {
      base_directory: path,
      items: { items: [] },
    };
    let selectKey = selectedRowKeys.concat([]);
    selectKey.forEach(item => {
      let name = this.filterDirectory(item, dataList).name;
      params['items']['items'].push(name);
    });

    home.downloadMultiple(params).then(res => {
      this.props.dispatch(Actions.switchLoading(false));
      if (res.data.Code === 'Success') {
        let link = document.createElement('a');
        link.target = '_blank';
        link.href = res.data.Data.url;
        link.click();
        link.remove();
        message.success(getLangMessage('download_file_text'));
      } else {
        message.error(res.data.Message);
      }
      // var disposition = res.headers['content-disposition']
      // var fileName = decodeURI(disposition.substring(disposition.indexOf('filename=') + 9, disposition.length))
      // let blob = new Blob([res.data], { type: '' })
      // let link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // link.download = fileName
      // link.click()
      // link.remove()
    });
  };

  // extract = () => {
  //   // 解压缩
  //   const path = getQueryUrl("path");
  //   const {selectedRowKeys,dataList} = this.props.state;
  //   if(selectedRowKeys.length>1){
  //     message.warn("解压文件只能解压单个");
  //     return ;
  //   }
  //   let selectKey = selectedRowKeys.concat([]);
  //   let name = this.filterDirectory(selectKey[0],dataList).name;
  //   if(!filterZipRar(name)){
  //     message.warn("解压只能解压单个压缩文件");
  //     return ;
  //   }
  //    this.setState({
  //     unpackName: name,
  //     uppackVisble: true,
  //     savePath: path
  //   })

  // }
  singleExtract = record => {
    if (parseInt(filterUnit(record.size)) > 5 * 1024 * 1024) {
      message.warn(getLangMessage('extract_file_limit'));
      return;
    }
    // 单个文件解压缩
    const path = getQueryUrl('path');
    const { dataList } = this.props.state;
    let selectKey = record.address;
    let name = this.filterDirectory(selectKey, dataList).name;
    this.setState({
      unpackName: name,
      uppackVisble: true,
      savePath: path,
    });
  };
  unpackOk = (filename, savePath, password) => {
    // 解压弹窗确认事件
    this.setState({
      unpackLoading: true,
    });
    const { selectedRowKeys } = this.props.state;
    let params = {
      remote_path: selectedRowKeys[0],
      password: password,
      to_directory: savePath,
    };
    home.extract(params).then(res => {
      if (res.data.Code === 'Success') {
        if (res.data.Data.status === 1) {
          message.success(getLangMessage('submit_success_text'));
          this.setState({
            uppackVisble: false,
            savePath: '',
            unpackName: '',

            isSizeSort: '',
            isTimeSort: '',
            isNameSort: false,
          });
          let searchText = getQueryUrl('searchText');
          if (!searchText) {
            this.props.dispatch(Actions.getDirectory());
          } else {
            this.props.dispatch(Actions.getSearchDirectory());
          }
        } else {
          message.error(res.data.Message);
        }
      } else {
        message.error(res.data.Message);
      }
      this.setState({
        unpackLoading: false,
      });
    });
    this.setState({
      uppackVisble: true,
    });
  };
  unpackCancel = () => {
    // 解压弹窗的取消事件
    this.setState({
      uppackVisble: false,
    });
  };
  compress = () => {
    const path = getQueryUrl('path');
    this.setState({
      packVisble: true,
      savePath: path,
    });
  };
  packOk = values => {
    // 压缩弹窗确认事件
    this.setState({
      packLoading: true,
    });
    const { selectedRowKeys, dataList } = this.props.state;

    let path = getQueryUrl('path');
    let params = {
      base_directory: path,
      to_directory: values,
      items: [],
    };
    let selectKey = selectedRowKeys.concat([]);
    selectKey.forEach(item => {
      let name = this.filterDirectory(item, dataList).name;
      params['items'].push(name);
    });
    home.compress(params).then(res => {
      if (res.data.Code === 'Success') {
        if (res.data.Data.status === 1) {
          message.success(getLangMessage('submit_success_text'));
          this.setState({
            packVisble: false,
            savePath: '',

            isSizeSort: '',
            isTimeSort: '',
            isNameSort: false,
          });
          let searchText = getQueryUrl('searchText');
          if (!searchText) {
            this.props.dispatch(Actions.getDirectory());
          } else {
            this.props.dispatch(Actions.getSearchDirectory());
          }
        } else {
          message.error(res.data.Message);
        }
      } else {
        message.error(res.data.Message);
      }
      this.setState({
        packLoading: false,
      });
    });
  };
  packCancel = () => {
    // 压缩弹窗的取消事件
    this.setState({
      packVisble: false,
      savePath: '',
    });
  };
  selectRootPath = () => {
    // 选择目录
    this.setState({
      remoteDirectoryVisble: true,
    });
  };

  renameOk = values => {
    // 下载存储弹窗确认事件
    this.setState({
      renameLoading: true,
    });
    const { selectedRowKeys } = this.props.state;

    let path = getQueryUrl('path');
    let params = null;
    if (path == '/') {
      params = {
        source: selectedRowKeys[0],
        destination: path + values.filename,
      };
    } else {
      params = {
        source: selectedRowKeys[0],
        destination: path + '/' + values.filename,
      };
    }
    home.rename(params).then(res => {
      this.setState({
        renameLoading: false,
      });
      if (res.data.Code === 'Success') {
        if (res.data.Data.status === 1) {
          message.success(getLangMessage('rename_success_text'));
          this.setState({
            renameVisble: false,
            currentEditName: '',
            isSizeSort: '',
            isTimeSort: '',
            isNameSort: false,
          });
          let searchText = getQueryUrl('searchText');
          if (!searchText) {
            this.props.dispatch(Actions.getDirectory());
          } else {
            this.props.dispatch(Actions.getSearchDirectory());
          }
        } else {
          message.error(res.data.Message);
        }
      } else {
        message.error(res.data.Message);
      }
    });
  };
  renameCancel = () => {
    // 下载存储弹窗的取消事件
    this.setState({
      renameVisble: false,
      currentEditName: '',
    });
  };
  renameEdit = record => {
    this.setState({
      renameVisble: true,
      currentEditName: record.name,
    });
  };

  remoteDirectoryOk = values => {
    // 远程目录弹窗确认事件
    this.setState({
      remoteDirectoryVisble: false,
      savePath: values,
    });
  };
  remoteDirectoryCancel = () => {
    // 远程目录弹窗的取消事件
    this.setState({
      remoteDirectoryVisble: false,
    });
  };

  enterPath = (e, record) => {
    // 进入下一个目录
    e.preventDefault();
    e.stopPropagation();
    this.props.dispatch(Actions.allselectChange(false));
    let searchParams = encodeURIComponent(`path=${record.address}&searchText=`);
    this.props.history.push({ pathname: '/home', search: searchParams });
  };

  upload = () => {
    // 上传
    this.setState({
      uppackVisble: true,
    });
  };

  filterDirectory(address, data) {
    let currentItem = null;
    data.forEach((item, index) => {
      if (item.address === address) {
        currentItem = item;
      }
    });

    return currentItem;
  }
  changePath = name => {
    // 路径面包屑导航
    const { path } = this.props.state;
    const pathArr = path.split('/');
    if (!name) {
      let searchParams = encodeURIComponent(`path=/&searchText=`);
      this.props.history.push({ pathname: '/home', search: searchParams });
    } else {
      let address = '';
      for (let i = 0; i < pathArr.length; i++) {
        if (pathArr[i]) {
          if (pathArr[i] === name) {
            address += `/${pathArr[i]}`;
            break;
          } else {
            address += `/${pathArr[i]}`;
          }
        }
      }
      let searchParams = encodeURIComponent(`path=${address}&searchText=`);
      this.props.history.push({ pathname: '/home', search: searchParams });
    }
  };

  newFileCancel = () => {
    this.setState({
      newFileVisble: false,
    });
  };
  newFileOk = values => {
    const { createKey } = this.state;

    this.setState({
      newFileloading: true,
    });
    let path = '';
    if (getQueryUrl('path') == '/') {
      path = getQueryUrl('path') + values.filename;
    } else {
      path = getQueryUrl('path') + '/' + values.filename;
    }

    if (createKey == 1) {
      let params = {
        remote_path: path,
      };
      home.makeDirectory(params).then(res => {
        if (res.data.Code === 'Success') {
          if (res.data.Data.status === 1) {
            message.success(getLangMessage('create_folder_text'));
            this.setState({
              newFileVisble: false,
              createKey: 1,

              isSizeSort: '',
              isTimeSort: '',
              isNameSort: false,
            });
            let searchText = getQueryUrl('searchText');
            if (!searchText) {
              this.props.dispatch(Actions.getDirectory());
            } else {
              this.props.dispatch(Actions.getSearchDirectory());
            }
          } else {
            message.error(res.data.Message);
          }
        } else {
          message.error(res.data.Message);
        }
        this.setState({
          newFileloading: false,
        });
      });
    } else if (createKey == 2) {
      let params = {
        remote_path: path,
        file_contents: values.content,
      };
      home.newFile(params).then(res => {
        if (res.data.Code === 'Success') {
          if (res.data.Data.status === 1) {
            message.success(getLangMessage('create_file_text'));
            this.setState({
              newFileVisble: false,
              createKey: 1,

              isSizeSort: '',
              isTimeSort: '',
              isNameSort: false,
            });
            let searchText = getQueryUrl('searchText');
            if (!searchText) {
              this.props.dispatch(Actions.getDirectory());
            } else {
              this.props.dispatch(Actions.getSearchDirectory());
            }
          } else {
            message.error(res.data.Message);
          }
        } else {
          message.error(res.data.Message);
        }
        this.setState({
          newFileloading: false,
        });
      });
    } else if (createKey == 3) {
      const { newFilePath } = this.state;
      const { selectedRowKeys } = this.props.state;
      let createPath = '';
      if (newFilePath == '/') {
        createPath = newFilePath + values.filename;
      } else {
        createPath = newFilePath + '/' + values.filename;
      }
      let params = {
        remote_path: createPath,
      };
      home.makeDirectory(params).then(res => {
        if (res.data.Code === 'Success') {
          if (res.data.Data.status === 1) {
            message.success(getLangMessage('create_folder_text'));
            this.setState({
              newFileVisble: false,
              createKey: 1,

              isSizeSort: '',
              isTimeSort: '',
              isNameSort: false,
            });
            this.child.onLoadData(this.state.node);
            let searchText = getQueryUrl('searchText');
            if (!searchText) {
              this.props.dispatch(Actions.getDirectory(selectedRowKeys));
            } else {
              this.props.dispatch(Actions.getSearchDirectory(selectedRowKeys));
            }
          } else {
            message.error(res.data.Message);
          }
        } else {
          message.error(res.data.Message);
        }
        this.setState({
          newFileloading: false,
        });
      });
    }
  };

  handleMenuClick = e => {
    // 选择新建文件夹还是文件
    let title = '';
    if (e.key == 1) {
      title = getLangMessage('folder');
    } else {
      title = getLangMessage('file');
    }
    this.setState({
      createKey: e.key,
      newFileVisble: true,
      newFileName: title,
    });
  };
  singleDownload = record => {
    //单个文件下载
    if (parseInt(filterUnit(record.size)) > 100 * 1024 * 1024) {
      message.warn(getLangMessage('download_file_limit'));
      return;
    }
    this.props.dispatch(Actions.switchLoading(true));
    const { dataList } = this.props.state;
    let path = getQueryUrl('path');
    let params = {
      base_directory: path,
      items: { items: [] },
    };
    let selectKey = record.address;
    let name = this.filterDirectory(selectKey, dataList).name;
    params['items']['items'].push(name);

    home.downloadMultiple(params).then(res => {
      this.props.dispatch(Actions.switchLoading(false));
      if (res.data.Code === 'Success') {
        let link = document.createElement('a');
        link.target = '_blank';
        link.href = res.data.Data.url;
        link.click();
        link.remove();
        message.success(getLangMessage('download_file_text'));
      } else {
        message.error(res.data.Message);
      }

      // var disposition = res.headers['content-disposition']
      // var fileName = decodeURI(disposition.substring(disposition.indexOf('filename=') + 9, disposition.length))
      // let blob = new Blob([res.data], { type: '' })
      // let link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // link.download = fileName
      // link.click()
      // link.remove()
    });
  };

  refresh = () => {
    //刷新页面目录
    this.setState({
      isSizeSort: '',
      isTimeSort: '',
      isNameSort: false,
    });
    let searchText = getQueryUrl('searchText');
    if (!searchText) {
      this.props.dispatch(Actions.getDirectory());
    } else {
      this.props.dispatch(Actions.getSearchDirectory());
    }
  };

  moveCopyFile = key => {
    // 移动选择文件夹弹窗
    const path = getQueryUrl('path');
    let title = '';
    if (key === 1) {
      title = getLangMessage('remove_folder');
    } else {
      title = getLangMessage('copy_floder');
    }
    this.setState({
      moveAndCopy: key,
      selectDirTitle: title,
      selectDirVisble: true,
      savePath: path,
    });
  };
  selectDirOk = values => {
    // 移动选择文件夹确认弹窗
    const { moveAndCopy } = this.state;
    const { selectedRowKeys, dataList } = this.props.state;
    let selectKey = selectedRowKeys.concat([]);
    let isDirectory = this.filterDirectory(selectKey[0], dataList).isDirectory;
    let name = this.filterDirectory(selectKey[0], dataList).name;
    let distination = '';
    if (values == selectKey[0]) {
      message.warn(getLangMessage('remove_valid_test'));
      return;
    }
    this.setState({
      confirmLoading: true,
    });
    if (values == '/') {
      distination = values + `${name}`;
    } else {
      distination = values + `/${name}`;
    }

    if (moveAndCopy === 1) {
      let params = {
        source: selectKey[0],
        is_directory: isDirectory,
        destination: distination,
      };
      home.cut(params).then(res => {
        if (res.data.Code === 'Success') {
          if (res.data.Data.status === 1) {
            message.success(getLangMessage('remove_success_text'));
            this.setState({
              moveAndCopy: 1,
              selectDirVisble: false,

              savePath: '',
              isSizeSort: '',
              isTimeSort: '',
              isNameSort: false,
            });
            let searchText = getQueryUrl('searchText');
            if (!searchText) {
              this.props.dispatch(Actions.getDirectory());
            } else {
              this.props.dispatch(Actions.getSearchDirectory());
            }
          } else {
            message.error(res.data.Message);
          }
        } else {
          message.error(res.data.Message);
        }
        this.setState({
          confirmLoading: false,
        });
      });
    } else {
      let params = {
        source: selectKey[0],
        is_directory: isDirectory,
        destination: distination,
      };
      home.copy(params).then(res => {
        if (res.data.Code === 'Success') {
          if (res.data.Data.status === 1) {
            message.success(getLangMessage('copy_success_text'));
            this.setState({
              moveAndCopy: 1,
              selectDirVisble: false,

              savePath: '',
              isSizeSort: '',
              isTimeSort: '',
              isNameSort: false,
            });
            let searchText = getQueryUrl('searchText');
            if (!searchText) {
              this.props.dispatch(Actions.getDirectory());
            } else {
              this.props.dispatch(Actions.getSearchDirectory());
            }
          } else {
            message.error(res.data.Message);
          }
        } else {
          message.error(res.data.Message);
        }
        this.setState({
          confirmLoading: false,
        });
      });
    }
  };
  selectDirCancel = () => {
    // 移动选择文件夹取消弹窗
    this.setState({
      moveAndCopy: 1,
      selectDirVisble: false,
      savePath: '',
    });
  };

  serachKeywords = value => {
    // let keywords = value;
    let path = getQueryUrl('path');
    let searchParams = encodeURIComponent(`path=${path}&searchText=${value}`);
    this.props.history.push({ pathname: '/home', search: searchParams });
    // this.props.dispatch(Actions.getSearchDirectory({keywords}))
  };

  getFileContents = record => {
    // 查看文件内容
    this.props.dispatch(Actions.switchLoading(true));
    this.props.dispatch(Actions.saveEditContent(record));
    let remote_path = record.address;
    home.getFileContents({ remote_path }).then(res => {
      this.props.dispatch(Actions.switchLoading(false));
      if (res.data.Code === 'Success') {
        if (res.data.Data.status === 1) {
          if (filterImg(record.name)) {
            this.setState({
              previewVisble: true,
              imgInfo: {
                src: res.data.Data.content,
                name: record.name,
                address: record.address,
                size: res.data.Data.size,
                width: res.data.Data.width,
                height: res.data.Data.height,
                date: res.data.Data.modificationDate,
              },
            });
          } else {
            this.setState({
              editInitVal: res.data.Data.content,
              editVisble: true,
              code: res.data.Data.encode,
            });
          }
        } else {
          message.error(res.data.Message);
        }
      } else {
        message.error(res.data.Message);
      }
    });
  };

  handleTypeChange = e => {
    // 改变列表类型
    this.setState({
      listType: e.target.value,
    });
  };
  otherCheckChange = (e, list) => {
    // 另一个样式列表的选中事件处理
    const { dataList, selectedRowKeys } = this.props.state;
    let newData = dataList.concat([]);
    let newSelectKey = selectedRowKeys.concat([]);

    newData.forEach(item => {
      if (item.address === list.address) {
        item.checkAction = !list.checkAction;
      }
    });
    if (!list.checkAction) {
      let removeIndex = newSelectKey.indexOf(list.address);
      newSelectKey.splice(removeIndex, 1);
    } else {
      newSelectKey.push(list.address);
    }
    if (newSelectKey.length < newData.length) {
      this.props.dispatch(Actions.allselectChange(false));
    } else if (newSelectKey.length == newData.length) {
      this.props.dispatch(Actions.allselectChange(true));
    }
    this.props.dispatch(Actions.datalistChange(newData));
    this.props.dispatch(Actions.changeSelectKeys(newSelectKey));
  };
  onAllChange = () => {
    // 另外一种样式列表的全选
    const { dataList, isAllSelect } = this.props.state;
    let newData = dataList.concat([]);
    let newSelectKey = [];
    if (!isAllSelect) {
      newData.forEach(item => {
        newSelectKey.push(item.address);
      });
    }
    newData.forEach(item => {
      item.checkAction = !isAllSelect;
    });
    this.props.dispatch(Actions.datalistChange(newData));
    this.props.dispatch(Actions.changeSelectKeys(newSelectKey));
    this.props.dispatch(Actions.allselectChange(!isAllSelect));
  };

  editOk = (content, code) => {
    this.setState({
      editLoading: true,
    });
    const { currentEditContent } = this.props.state;
    let params = {
      remote_path: currentEditContent.address,
      content: content,
      encoding: code,
    };
    home.putFileContents(params).then(res => {
      if (res.data.Code === 'Success') {
        if (res.data.Data.status === 1) {
          message.success(getLangMessage('edit_success_text'));
          this.setState({
            editInitVal: '',
            editVisble: false,
          });
          this.props.dispatch(Actions.saveEditContent(null));
        } else {
          message.error(res.data.Message);
        }
      } else {
        message.error(res.data.Message);
      }
      this.setState({
        editLoading: false,
      });
    });
  };
  editCancel = () => {
    this.setState({
      editInitVal: '',
      editVisble: false,
    });
    this.props.dispatch(Actions.saveEditContent(null));
  };

  downloadImg = () => {
    const { currentEditContent } = this.props.state;
    this.singleDownload(currentEditContent);
  };
  deleteImg = () => {
    this.deleteOk();
  };
  closePreview = () => {
    this.setState({
      previewVisble: false,
      imgInfo: null,
    });
    this.props.dispatch(Actions.saveEditContent(null));
  };

  createDirectory = (path, node) => {
    // 新建文件夹
    if (!path && !node) {
      message.warn(getLangMessage('create_folder_valid_text'));
      return;
    }
    this.setState({
      newFileVisble: true,
      newFilePath: path,
      createKey: 3,
      newFileName: getLangMessage('folder'),
      node: node,
    });
  };
  sizeSort = () => {
    const { isSizeSort } = this.state;
    return (
      <div className="sortTitle" onClick={this.sortForSize}>
        <span
          className={
            isSizeSort !== '' ? (isSizeSort ? 'upActive' : 'downActive') : ''
          }>
          {getLangMessage('th_size')}
        </span>
      </div>
    );
  };
  sortForSize = () => {
    const { isSizeSort } = this.state;
    const { dataList } = this.props.state;
    if (isSizeSort === '') {
      this.setState({
        isSizeSort: true,
        isTimeSort: '',
        isNameSort: '',
      });

      let newData = dataList.concat([]);
      let sortData = newData.sort(compareSizeUp('size'));
      this.props.dispatch(Actions.sortSaveDirectory(sortData));
    } else {
      this.setState({
        isSizeSort: !isSizeSort,
        isTimeSort: '',
        isNameSort: '',
      });
      if (isSizeSort) {
        let newData = dataList.concat([]);
        let sortData = newData.sort(compareSizeDown('size'));
        this.props.dispatch(Actions.sortSaveDirectory(sortData));
      } else {
        let newData = dataList.concat([]);
        let sortData = newData.sort(compareSizeUp('size'));
        this.props.dispatch(Actions.sortSaveDirectory(sortData));
      }
    }
  };
  timeSort = () => {
    const { isTimeSort } = this.state;
    return (
      <div className="sortTitle" onClick={this.sortForTime}>
        <span
          className={
            isTimeSort !== '' ? (isTimeSort ? 'upActive' : 'downActive') : ''
          }>
          {getLangMessage('th_update')}
        </span>
      </div>
    );
  };
  sortForTime = () => {
    const { isTimeSort } = this.state;
    const { dataList } = this.props.state;
    if (isTimeSort === '') {
      this.setState({
        isTimeSort: true,
        isSizeSort: '',
        isNameSort: '',
      });

      let newData = dataList.concat([]);
      let sortData = newData.sort(compareTimeUp('modificationDate'));
      this.props.dispatch(Actions.sortSaveDirectory(sortData));
    } else {
      this.setState({
        isTimeSort: !isTimeSort,
        isSizeSort: '',
        isNameSort: '',
      });
      if (isTimeSort) {
        let newData = dataList.concat([]);
        let sortData = newData.sort(compareTimeDown('modificationDate'));
        this.props.dispatch(Actions.sortSaveDirectory(sortData));
      } else {
        let newData = dataList.concat([]);
        let sortData = newData.sort(compareTimeUp('modificationDate'));
        this.props.dispatch(Actions.sortSaveDirectory(sortData));
      }
    }
  };
  nameSort = () => {
    const { isNameSort } = this.state;
    return (
      <div className="sortTitle" onClick={this.sortForName}>
        <span
          className={
            isNameSort !== '' ? (isNameSort ? 'upActive' : 'downActive') : ''
          }>
          {getLangMessage('th_name')}
        </span>
      </div>
    );
  };
  sortForName = () => {
    const { isNameSort } = this.state;
    const { dataList } = this.props.state;
    let data = dataList.concat([]);
    let directoryData = [];
    let unDirectoryData = [];
    data.forEach(item => {
      if (item.isDirectory) {
        directoryData.push(item);
      } else {
        unDirectoryData.push(item);
      }
    });
    if (isNameSort === '') {
      this.setState({
        isNameSort: true,
        isSizeSort: '',
        isTimeSort: '',
      });
      directoryData = directoryData.sort(compareNameUp('name'));
      unDirectoryData = unDirectoryData.sort(compareNameUp('name'));

      let sortData = directoryData.concat(unDirectoryData);

      this.props.dispatch(Actions.sortSaveDirectory(sortData));
    } else {
      this.setState({
        isNameSort: !isNameSort,
        isSizeSort: '',
        isTimeSort: '',
      });
      if (isNameSort) {
        directoryData = directoryData.sort(compareNameDown('name'));
        unDirectoryData = unDirectoryData.sort(compareNameDown('name'));

        let sortData = directoryData.concat(unDirectoryData);
        this.props.dispatch(Actions.sortSaveDirectory(sortData));
      } else {
        directoryData = directoryData.sort(compareNameUp('name'));
        unDirectoryData = unDirectoryData.sort(compareNameUp('name'));

        let sortData = directoryData.concat(unDirectoryData);
        this.props.dispatch(Actions.sortSaveDirectory(sortData));
      }
    }
  };
  keywordChange = e => {
    this.setState({
      keyword: e.target.value,
    });
  };

  onRef = ref => {
    this.child = ref;
  };
  feedbackFalse = () => {
    this.props.dispatch(Actions.feedbackChange(false));
  };
  render() {
    const {
      imgInfo,
      deleteVisible,
      rightStyle,
      uppackVisble,
      packVisble,
      renameVisble,
      remoteDirectoryVisble,
      newFileVisble,
      selectDirVisble,
      listType,
      editVisble,
      editInitVal,
      code,
      previewVisble,
    } = this.state;
    const { state } = this.props;
    const {
      selectedRowKeys,
      dataList,
      path,
      isSearchDirectory,
      isAllSelect,
      currentEditContent,
      authPermission,
      feedbackStatus,
    } = state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        width: '50%',
        title: this.nameSort(),
        dataIndex: 'name',
        render: (text, record, index) => {
          return (
            <div className="tableEdit">
              {record.hoverAction ? (
                <div>
                  {record.isDirectory ? (
                    <React.Fragment>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={e => this.enterPath(e, record)}>
                        {filterTypeFile(record.isDirectory, text)}
                        {getQueryUrl('searchText')?record.address:text}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span style={{ cursor: 'pointer' }}>
                        {filterTypeFile(record.isDirectory, text)}
                        {getQueryUrl('searchText')?record.address:text}
                      </span>
                    </React.Fragment>
                  )}
                  <Icon
                    type="edit"
                    style={{ color: '#00aaff', marginLeft: '8px' }}
                    onClick={() => this.renameEdit(record)}
                  />
                  <div className="operate">
                    {!record.isDirectory ? (
                      !filterZipRar(record.name) ? (
                        filterImg(record.name) ? (
                          <span
                            className="check"
                            title={getLangMessage('view_file')}
                            onClick={() => this.getFileContents(record)}></span>
                        ) : (
                          <span
                            className="edit"
                            title={getLangMessage('edit_file')}
                            onClick={() => this.getFileContents(record)}></span>
                        )
                      ) : null
                    ) : null}
                    {!record.isDirectory ? (
                      <span
                        className="download"
                        title={getLangMessage('download')}
                        onClick={() => this.singleDownload(record)}></span>
                    ) : null}
                    <span
                      className="copy"
                      title={getLangMessage('copy')}
                      onClick={() => this.moveCopyFile(2)}></span>
                    <span
                      className="move"
                      title={getLangMessage('remove')}
                      onClick={() => this.moveCopyFile(1)}></span>
                    <span
                      className="delete"
                      title={getLangMessage('delete_title')}
                      onClick={this.deleteFile}></span>
                    {filterZipRar(record.name) ? (
                      <span
                        className="rar"
                        title={getLangMessage('decompress')}
                        onClick={() => this.singleExtract(record)}></span>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div>
                  {record.isDirectory ? (
                    <React.Fragment>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={e => this.enterPath(e, record)}>
                        {filterTypeFile(record.isDirectory, text)}
                        {getQueryUrl('searchText')?record.address:text}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span style={{ cursor: 'pointer' }}>
                        {filterTypeFile(record.isDirectory, text)}
                        {getQueryUrl('searchText')?record.address:text}
                      </span>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          );
        },
      },
      {
        width: '20%',
        title: this.sizeSort(),
        dataIndex: 'size',
      },
      {
        width: '30%',
        title: this.timeSort(),
        dataIndex: 'modificationDate',
      },
    ];
    const createMenu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">{getLangMessage('create_folder')}</Menu.Item>
        <Menu.Item key="2">{getLangMessage('create_file')}</Menu.Item>
      </Menu>
    );

    let that = this;
    const uploadProps = {
      action: '',
      multiple: false,
      data: {},
      onStart(file) {
        // console.log('onStart', file, file.name);
      },
      onSuccess(ret, file) {
        // console.log('onSuccess', ret, file.name);
      },
      onError(err) {
        // console.log('onError', err);
      },
      onProgress({ percent }, file) {
        // console.log('onProgress', `${percent}%`, file.name);
      },
      customRequest({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
      }) {
        //创建FormData对象
        var formData = new FormData();
        let path = '';
        if (getQueryUrl('path') == '/') {
          path = getQueryUrl('path') + file.name;
        } else {
          path = getQueryUrl('path') + '/' + file.name;
        }
        // console.log("size",file.size);
        let maxSize = 20 * 1024 * 1024;
        if (file.size > maxSize) {
          message.warn(getLangMessage('upload_valid_text'));
          return;
        }

        formData.append('file', file);
        formData.append('remote_path', `${path}`);
        formData.append('is_cover', 1);
        that.props.dispatch(Actions.switchLoading(true));

        home.upload(formData).then(res => {
          that.props.dispatch(Actions.switchLoading(false));
          if (res.data.Code === 'Success') {
            if (res.data.Data.status === 1) {
              message.success(getLangMessage('upload_success_text'));
              that.setState({
                isSizeSort: '',
                isTimeSort: '',
              });
              let searchText = getQueryUrl('searchText');
              if (!searchText) {
                that.props.dispatch(Actions.getDirectory());
              } else {
                that.props.dispatch(Actions.getSearchDirectory());
              }
            } else {
              message.error(res.data.Message);
            }
          } else {
            message.error(res.data.Message);
          }
        });

        return {
          abort() {
            // console.log('upload progress is aborted.');
          },
        };
      },
    };
    return (
      <div className="home_list">
        <div className="home_action">
          <div className="btnGroup">
            <div className="uploadMethods">
              <Upload {...uploadProps}>
                <Button
                  type="primary"
                  style={{ width: '100px', height: '32px' }}>
                  <FormattedMessage id="upload"></FormattedMessage>
                </Button>
              </Upload>
            </div>

            <Dropdown overlay={createMenu}>
              <Button style={{ width: '100px' }}>
                <FormattedMessage id="create"></FormattedMessage>{' '}
                <Icon type="down" />
              </Button>
            </Dropdown>
            {/* <Button type="primary" onClick={this.downloadFile}  disabled={!selectedRowKeys.length?true:false} ghost>下载</Button> */}
            {/* <Button
              type="primary"
              onClick={this.compress}
              disabled={!selectedRowKeys.length ? true : false}
              ghost>
              <FormattedMessage id="compress"></FormattedMessage>
            </Button> */}
            {/* <Button type="primary" onClick={this.extract} disabled={!selectedRowKeys.length?true:false} ghost>解压缩</Button> */}
            <Button
              type="primary"
              onClick={this.deleteFile}
              disabled={!selectedRowKeys.length ? true : false}
              ghost>
              <FormattedMessage id="delete_title"></FormattedMessage>
            </Button>
          </div>
          <div className="searchGroup">
            <Search
              placeholder={getLangMessage('search_placeholder')}
              onChange={this.keywordChange}
              value={this.state.keyword}
              style={{ width: '320px', marginRight: '20px' }}
              onSearch={value => this.serachKeywords(value)}
              allowClear
              enterButton
            />
            <Button
              type="default"
              icon="redo"
              style={{ marginRight: '16px' }}
              onClick={this.refresh}
            />
            <Radio.Group value={listType} onChange={this.handleTypeChange}>
              <Radio.Button value="1">
                <Icon type="unordered-list" />
              </Radio.Button>
              <Radio.Button value="2">
                <Icon type="appstore" />
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {isSearchDirectory ? (
          <ul className="navPath">
            <li onClick={() => this.props.history.go(-1)}>
              <FormattedMessage id="back_to_top"></FormattedMessage>
            </li>
            <li onClick={() => this.changePath()} key={2}>
              <FormattedMessage id="root_directory"></FormattedMessage>
            </li>
            <li key={3}>
              <FormattedMessage id="search"></FormattedMessage>：
              {this.props.state.searchText}
            </li>
          </ul>
        ) : path === '/' ? (
          <p>
            <FormattedMessage id="all_directory"></FormattedMessage>
          </p>
        ) : (
          <ul className="navPath">
            <li onClick={() => this.props.history.go(-1)}>
              <FormattedMessage id="back_to_top"></FormattedMessage>
            </li>

            {path.split('/').map((item, index) => {
              if (!item) {
                return (
                  <li onClick={() => this.changePath(item)} key={index}>
                    <FormattedMessage id="root_directory"></FormattedMessage>
                  </li>
                );
              } else {
                return (
                  <li onClick={() => this.changePath(item)} key={index}>
                    {item}
                  </li>
                );
              }
            })}
          </ul>
        )}

        {authPermission ? (
          listType == 1 ? (
            <Table
              id="tableList"
              onRow={(record, index) => {
                return {
                  onClick: event => {
                    // 点击行
                    event.preventDefault();
                    let data = [record.address];
                    this.props.dispatch(Actions.changeSelectKeys(data));
                    const { dataList } = this.props.state;
                    let newData = dataList.concat([]);

                    newData.forEach(item => {
                      if (item.address == record.address) {
                        item.checkAction = true;
                      } else {
                        item.checkAction = false;
                      }
                    });
                    this.props.dispatch(Actions.datalistChange(newData));
                  },
                  onDoubleClick: event => {},
                  onContextMenu: event => {
                    // 右键点击事件
                    event.preventDefault();
                    event.stopPropagation();
                    let clientX = 0;
                    let clientY = 0;
                    let bodyHeight = document.body.clientHeight;
                    let diffY = record.isDirectory ? 120 : 180;
                    if (bodyHeight - event.clientY < diffY) {
                      clientY = event.clientY - diffY;
                    } else {
                      clientY = event.clientY;
                    }
                    clientX = event.clientX + 5;
                    let style = Object.assign({}, this.state.rightStyle, {
                      left: clientX,
                      top: clientY,
                    });
                    this.setState({
                      rightStyle: style,
                      rightKey_directory: record.isDirectory,
                    });

                    let data = [record.address];
                    const { dataList } = this.props.state;
                    let newData = dataList.concat([]);

                    newData.forEach(item => {
                      if (item.address == record.address) {
                        item.checkAction = true;
                      } else {
                        item.checkAction = false;
                      }
                    });
                    this.props.dispatch(Actions.datalistChange(newData));
                    this.props.dispatch(Actions.changeSelectKeys(data));
                    this.props.dispatch(Actions.openRightKey());
                  },
                  onMouseEnter: event => {
                    let newData = dataList.concat([]);
                    newData[index].hoverAction = true;
                    this.props.dispatch(Actions.datalistChange(newData));
                  },
                  onMouseLeave: event => {
                    let newData = dataList.concat([]);

                    newData[index].hoverAction = false;

                    this.props.dispatch(Actions.datalistChange(newData));
                  },
                };
              }}
              pagination={false}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={dataList}
              rowKey={record => record.address}
              loading={state.tableLoading}
              style={{ padding: '0 40px' }}
              // scroll={{ y: 600 }}
            />
          ) : (
            <React.Fragment>
              <div style={{ margin: '0 40px' }}>
                <Checkbox
                  checked={isAllSelect}
                  onChange={() => this.onAllChange()}>
                  {getLangMessage('check_all')}
                </Checkbox>
              </div>
              <ul className="otherTypeList">
                {dataList.length > 0 ? (
                  dataList.map((item, index) => (
                    <li
                      key={index}
                      className={item.checkAction ? 'active' : ''}>
                      {item.isDirectory ? (
                        <div
                          className="clickArea"
                          onClick={e => this.enterPath(e, item)}></div>
                      ) : (
                        <div className="clickArea"></div>
                      )}
                      {filterTypeFileTwo(item.isDirectory, item.name)}
                      {/* <div className="directoryType"></div> */}
                      <p className="typeName">{item.name}</p>
                      <div className="checkList">
                        <Checkbox
                          checked={item.checkAction}
                          onChange={e =>
                            this.otherCheckChange(e, item)
                          }></Checkbox>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="emptyData">
                    <div>
                      <Icon type="close-circle" />
                    </div>
                    <div>
                      <FormattedMessage id="no_data_text"></FormattedMessage>
                    </div>
                  </li>
                )}
              </ul>
            </React.Fragment>
          )
        ) : (
          <AuthPermission />
        )}

        {/* 删除弹窗 */}
        <DeleteModal
          visible={deleteVisible}
          deleteOk={this.deleteOk}
          deleteCancel={this.deleteCancel}
          deleteLoading={this.state.deleteLoading}
        />
        {/* 右键操作 */}
        {state.rightKeyVisble ? (
          <RightKey
            styleObj={rightStyle}
            rightOpen={this.OpenFile}
            rightDownload={this.downloadFile}
            rightCopy={() => this.moveCopyFile(2)}
            rightMove={() => this.moveCopyFile(1)}
            rightRename={this.renameEdit}
            rightDelete={this.deleteFile}
            idDirectory={this.state.rightKey_directory}
          />
        ) : null}

        {/* 解压弹窗 */}
        {uppackVisble ? (
          <UnpackModal
            unpackOk={this.unpackOk}
            unpackCancel={this.unpackCancel}
            selectPath={this.selectRootPath}
            savePath={this.state.savePath}
            filename={this.state.unpackName}
            unpackLoading={this.state.unpackLoading}
          />
        ) : null}

        {/* 压缩弹窗 */}
        {packVisble ? (
          <PackModal
            packOk={this.packOk}
            packCancel={this.packCancel}
            selectPath={this.selectRootPath}
            savePath={this.state.savePath}
            packLoading={this.state.packLoading}
          />
        ) : null}

        {/* 重命名弹窗 */}
        {renameVisble ? (
          <RenameModal
            renameOk={this.renameOk}
            renameCancel={this.renameCancel}
            originName={this.state.currentEditName}
            renameLoading={this.state.renameLoading}
          />
        ) : null}

        {/* 选择远程目录弹窗 */}
        {remoteDirectoryVisble ? (
          <RemoteDirectory
            remoteDirectoryCancel={this.remoteDirectoryCancel}
            remoteDirectoryOk={this.remoteDirectoryOk}
            createDirectory={this.createDirectory}
            onRef={this.onRef}
          />
        ) : null}

        {/* 新建文件/文件夹 */}
        {newFileVisble ? (
          <NewFile
            newFileCancel={this.newFileCancel}
            newFileOk={this.newFileOk}
            newFileloading={this.state.newFileloading}
            newFileName={this.state.newFileName}
            typeKey={this.state.createKey}
            
          />
        ) : null}

        {/*移动复制文件夹选择文件目录弹窗  */}
        {selectDirVisble ? (
          <SelectDirectory
            title={this.state.selectDirTitle}
            selectDirOk={this.selectDirOk}
            selectDirCancel={this.selectDirCancel}
            selectPath={this.selectRootPath}
            savePath={this.state.savePath}
            confirmLoading={this.state.confirmLoading}
          />
        ) : null}

        {/* 编辑弹窗 */}
        {editVisble ? (
          <EditModal
            mode="javascript"
            fontSize={16}
            editOk={this.editOk}
            editCancel={this.editCancel}
            name={currentEditContent.name}
            initVal={editInitVal}
            code={code}
            editLoading={this.state.editLoading}
           
          />
        ) : null}
        {/* 图片预览插件 */}
        {previewVisble ? (
          <PreviewImg
            downloadImg={this.downloadImg}
            deleteImg={this.deleteImg}
            closePreview={this.closePreview}
            info={imgInfo}
          />
        ) : null}
        {feedbackStatus ? (
          <div onClick={this.feedbackFalse} className="maskModal"></div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    state: state,
  };
}

export default connect(mapStateToProps)(Form.create()(Home));
