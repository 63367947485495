import axios from '../../utils/axios'
import * as url from './urlConfig'
import {message} from 'antd';


export const user = {
    logout(param) {
      return axios.post(url.logout, param)
        .then((response) => {
          let data = response;
          if (data.status === 200) {
            return data;
          }else{
            message.error("api error");
          }
         
        })
        .catch(function (error) {
          message.error(error)
        })
    },
    feedback(param) {
      return axios.post(url.feedback, param)
        .then((response) => {
          let data = response;
          if (data.status === 200) {
            return data;
          }else{
            message.error("api error");
          }
         
        })
        .catch(function (error) {
          message.error(error)
        })
    },

}