import {filterUnit} from './index'


export const  compareSizeUp =(property)=> {
    return function (a, b) {
        let aSize=filterUnit(a[property]);
        let bSize=filterUnit(b[property]);
        return bSize-aSize;
     
    }
  }
  export const  compareSizeDown =(property) => {
    return function (a, b) {
        let aSize=filterUnit(a[property]);
        let bSize=filterUnit(b[property]);
        return aSize-bSize;
    }
  }



  export const  compareTimeUp = (property) => {
    return function (a, b) {
      let time1 =  new Date(a[property]).getTime()
      let time2 = new Date(b[property]).getTime();
      return time2-time1
    }
  }
  export const  compareTimeDown = (property) => {
    return function (a, b) {
      let time1 =  new Date(a[property]).getTime()
      let time2 = new Date(b[property]).getTime();
      return time1-time2
    }
  }

  export const  compareNameDown = (prop) => {
    return function (obj1, obj2) {
        var val1 = obj1[prop];
        var val2 = obj2[prop];
      

       return val1.charCodeAt(0)  -val2.charCodeAt(0) ;            
    } 
}

export const  compareNameUp = (prop) => {
  return function (obj1, obj2) {
      var val1 = obj1[prop];
      var val2 = obj2[prop];

     return val2.charCodeAt(0)  -val1.charCodeAt(0) ;            
  } 
}