// example 2

import { call, put,takeEvery } from 'redux-saga/effects';
import axios from '../utils/axios';
import {message} from 'antd';

import {getQueryUrl} from '../utils'

import {DIRECTORY_GET,DIRECTORY_SAVE,TREEDIRECTORY_GET,TREEDIRECTORY_SAVE,SEARCHDIRECTORY_GET} from './actionType.js';
 
// 1. our worker saga
export function* createLessonAsync(action) {
    try {
        let path =getQueryUrl("path");
        let searchText = getQueryUrl("searchText");
        const response = yield call(axios.post, '/list_directory', {path:path});
        let selectedRowKeys = action.location?action.location.selectedRowKeys : [];
        if(response.data.Code === "Success"){
            let directoryData = [];
            let unDirectoryData = [];
            response.data.Data.forEach((item)=>{
                item.hoverAction = false;
                item.checkAction = false;
                if(item.isDirectory){
                    directoryData.push(item)
                }else{
                    unDirectoryData.push(item);
                }
            })
            directoryData = directoryData.sort(compare("name"))
            unDirectoryData = unDirectoryData.sort(compare("name"));

            let responseData = directoryData.concat(unDirectoryData);
            
            yield put({type: DIRECTORY_SAVE, data: responseData,path:path,searchText:searchText,isSearchDirectory:false,authPermission:true,selectedRowKeys:selectedRowKeys});
        }else{
            message.error(response.data.Message);
            yield put({type: DIRECTORY_SAVE, data: [],path:path,searchText:searchText,isSearchDirectory:false,authPermission: false,selectedRowKeys:selectedRowKeys});
        } 

       
       
    } catch(e) {
        console.log(e);
    }
}

export function* getSearchDirectoryAsync(action) {
    try {
        let path =getQueryUrl("path");
        let keywords =getQueryUrl("searchText");
        const response = yield call(axios.post, '/search', {base_directory:path,keywords});
        let selectedRowKeys = action.location?action.location.selectedRowKeys : [];
        if(response.data.Code === "Success"){
            let directoryData = [];
            let unDirectoryData = [];
            response.data.Data.forEach((item)=>{
                item.hoverAction = false;
                item.checkAction = false;
                if(item.isDirectory){
                    directoryData.push(item)
                }else{
                    unDirectoryData.push(item);
                }
            })
            directoryData = directoryData.sort(compare("name"))
            unDirectoryData = unDirectoryData.sort(compare("name"));

            let responseData = directoryData.concat(unDirectoryData);
            
            yield put({type: DIRECTORY_SAVE, data: responseData,path:path,searchText:keywords,isSearchDirectory:true,authPermission:true,selectedRowKeys:selectedRowKeys});
        }else{
            message.error(response.data.Message);
        } 

       
       
    } catch(e) {
        console.log(e);
    }
}

export function* getTreeDirectoryAsync(action) {
    try {
     
        const tree = yield  call(axios.post, '/tree_directory', {path:'/'});
        if(tree.data.Code === "Success"){
            yield put({type: TREEDIRECTORY_SAVE, data: tree.data.Data});
        }else{
            message.error(tree.data.Message);
        } 
       
    } catch(e) {
        console.log(e);
    }
}
 
// 2. our watcher saga: spawn a new task on each ACTION
export function* watchCreateLesson() {
    // takeEvery: 
    // listen for certain actions that are going to be dispatched and take them and run through our worker saga.
    yield takeEvery(DIRECTORY_GET, createLessonAsync);
    yield takeEvery(TREEDIRECTORY_GET, getTreeDirectoryAsync);
    yield takeEvery(SEARCHDIRECTORY_GET, getSearchDirectoryAsync);
}
 
 
// 3. our root saga: single entry point to start our sagas at once   
export default function* rootSaga() {

    yield watchCreateLesson()
}


function compare(prop) {
    return function (obj1, obj2) {
        var val1 = obj1[prop];
        var val2 = obj2[prop];
        if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
            val1 = Number(val1);
            val2 = Number(val2);
        }
       return val2 -val1;            
    } 
}