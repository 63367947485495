import React from 'react';
import zh_CN from "../locale/zh_CN";
import en_US from "../locale/en_US";
import store from '../store'

export const filterTypeFile = (isDirectory,name) => {
    if(isDirectory){
        return <span className="directoryFile fileIcon" ></span>
    }else{
        let suffix = getSuffix(name);
        switch(suffix){
            case 'txt': 
                return <span className="txtFile fileIcon"></span>;
                // break;
            case 'pdf': 
                return <span className="pdfFile fileIcon"></span>;
                // break;
            case 'ppt': 
                return <span className="pptFile fileIcon"></span>;
                // break;
            case 'pptx': 
                return <span className="pptFile fileIcon"></span>;
                // break;
            case 'docx': 
                return <span className="docFile fileIcon"></span>;
                // break;
            case 'doc': 
                return <span className="docFile fileIcon"></span>;
            // break;
            case 'xlsx': 
            return <span className="xlsxFile fileIcon"></span>;
            // break;
            case 'csv': 
            return <span className="xlsxFile fileIcon"></span>;
            // break;
            case 'jpg': 
            return <span className="imgFile fileIcon"></span>;
            // break;
            case 'jpeg': 
            return <span className="imgFile fileIcon"></span>;
            // break;
            case 'png': 
            return <span className="imgFile fileIcon"></span>;
            // break;
            case 'gif': 
            return <span className="imgFile fileIcon"></span>;
            // break;
            case 'bmp': 
            return <span className="imgFile fileIcon"></span>;
            // break;
            case 'zip': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case 'rar': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case '7z': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case 'gz': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case 'bz2': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case 'xz': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case 'tar': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case 'tar.gz': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case 'tar.bz2': 
            return <span className="zipFile fileIcon"></span>;
            // break;
            case 'tar.xz': 
            return <span className="zipFile fileIcon"></span>;
            // break;
           default :
                return <span className="defaultFile fileIcon"></span>;
                // break;
        }
    }
}
export const filterTypeFileTwo = (isDirectory,name) => {
    if(isDirectory){
        return <div className="directoryFile2 fileIcon2"></div>
    }else{
        let suffix = getSuffix(name);
        switch(suffix){
            case 'txt': 
                return <div className="txtFile2 fileIcon2"></div>;
                // break;
            case 'pdf': 
                return <div className="pdfFile2 fileIcon2"></div>;
                // break;
             case 'ppt': 
                return <div className="pptFile2 fileIcon2"></div>;
                // break;
             case 'pptx': 
                return <div className="pptFile2 fileIcon2"></div>;
                // break;
            case 'docx': 
                return <div className="docFile2 fileIcon2"></div>;
                // break;
            case 'doc': 
                return <div className="docFile2 fileIcon2"></div>;
                // break;
            case 'xlsx': 
            return <div className="xlsxFile2 fileIcon2"></div>;
            // break;
            case 'csv': 
            return <div className="xlsxFile2 fileIcon2"></div>;
            // break;
            case 'jpg': 
            return <div className="imgFile2 fileIcon2"></div>;
            // break;
            case 'jpeg': 
            return <div className="imgFile2 fileIcon2"></div>;
            // break;
            case 'png': 
            return <div className="imgFile2 fileIcon2"></div>;
            // break;
            case 'gif': 
            return <div className="imgFile2 fileIcon2"></div>;
            // break;
            case 'bmp': 
            return <div className="imgFile2 fileIcon2"></div>;
            // break;
            case 'zip': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case 'rar': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case '7z': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case 'gz': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case 'bz2': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case 'xz': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case 'tar': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case 'tar.gz': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case 'tar.bz2': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
            case 'tar.xz': 
            return <div className="zipFile2 fileIcon2"></div>;
            // break;
           default :
                return <div className="defaultFile2 fileIcon2"></div>;
                // break;
        }
    }
}

export const filterZipRar = (name) => {
    // 匹配zip,rar文件 
    let reg=/^\w+\.(rar|zip|gz)$/;
    return reg.test(name);

}

export const  getQueryUrl = (variable) =>{
    // 获取url参数
       var query = decodeURIComponent(window.location.hash.split("?")[1]);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}

function getSuffix(str){

    var index=str.lastIndexOf(".");
    str=str.substring(index+1,str.length);
    return str;
}


export const filterImg = (name) => {
    // 匹配jpg,jpeg,gif,png文件 
    // let reg=/^\w+\.(jpeg|png|gif|jpg|bmp)$/;
    // let reg = /^.*?\.([j,J][p,P][g,G]|[j,J][p,P][e,E][g,G]|[b,B][m,M][p,P]|[g,G][i,I][f,F])$/;
    // return reg.test(name);
    var strRegex = "(.jpg|.png|.gif|.ps|.jpeg|.bmp)$"; //用于验证图片扩展名的正则表达式
    var re=new RegExp(strRegex);
    if (re.test(name.toLowerCase())){
        return true;
    } else{
        return false;
    }

}

export const filterUnit = (name) => {
    let reg=/^(\d+\.?\d*)([A-Z]+)$/;

    let a = name.match(reg);
    let size = 0;
    if(!name || name == "-"){
        size = 0;
        return size;
    }
    let unit = a[2].toUpperCase();
    switch(unit){
        case "B":
           size = a[1];
           break;
        case "KB":
            size  = a[1]*1024;
            break; 
        case "MB":
            size  = a[1]*1024*1024;
            break; 
        case "GB":
            size  = a[1]*1024*1024*1024;
            break; 
        case "T":
            size  = a[1]*1024*1024*1024*1024;
            break; 
        default:
           size = 0  
    }
    return size;

}

export const getLangMessage = (name) => {
    let lang = store.getState().language;
    let messages = {};
    messages['en-us'] = en_US;
    messages['zh-cn'] = zh_CN;
    return messages[lang][name] || ''
}


export const setCookie = (name,value) => {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days*24*60*60*30);
    document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
}

export const getCookie = (name) => {
    var aCookie = document.cookie.split("; ");
    for (var i=0; i < aCookie.length; i++)
    {
      var aCrumb = aCookie[i].split("=");
      if (escape(name) == aCrumb[0])
        return unescape(aCrumb[1]);
    }
    return null;
}
