

import { createStore, applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer'
import rootSaga from './saga'

const win = window;

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const storeEnhancers = compose(
  applyMiddleware(...middlewares),
  win && win.devToolsExtension ? win.devToolsExtension() : f => f
);
let sitename =localStorage.getItem("sitename");
let host =localStorage.getItem("host");

const getCookie = (name) => {
  var aCookie = document.cookie.split("; ");
  for (var i=0; i < aCookie.length; i++)
  {
    var aCrumb = aCookie[i].split("=");
    if (escape(name) == aCrumb[0])
      return unescape(aCrumb[1]);
  }
  return null;
}
let lang = getCookie("think_var");

const initValues = {
    'customName': '',
    'adminName': sitename || '',   //系统名称
    'language': lang || 'zh-cn',                     //系统语言
    'dataList':[],                        //目录列表
    'path':'/',                           //当前路径
    'rightKeyVisble': false,              //右键菜单
    'treeDirectory':null,                 //全部文件目录
    'tableLoading': true,                 //表格加载状态
    'selectedRowKeys': [],                      //选中的文件目录或文件夹
    'switchloading':false,                     //http请求加载动画
    'isSearchDirectory': false,              //判断是否属于搜索目录来控制搜索导航
    'searchText': '',                       //搜索的字段
    'isAllSelect': false,                   //另一种模式的全选

    'currentEditContent': null,             //当前编辑的内容

    'authPermission': true,                //是否有权限查看当前目录
    'host': host?host:'',
    'shortCutsObj': null,                 //快捷键的剪切复制对象存储
    'feedbackStatus': false,               //意见反馈弹窗
  };
const store = createStore(reducer, initValues, storeEnhancers);
sagaMiddleware.run(rootSaga);

export default store;