import Axios from 'axios'
import {getQueryUrl} from './index'
import {createHashHistory} from 'history';
const history = createHashHistory();



var axios = Axios.create({
  baseURL: "/api",
  // timeout: 5000,
  withCredentials: true,
})
//添加一个请求拦截器
axios.interceptors.request.use(function (config) {
  const token = getQueryUrl("token");
  if(token){
    localStorage.setItem("access-token",token);
  }
  let ACCESS_TOKEN = localStorage.getItem('access-token');
  if(ACCESS_TOKEN){
    config.headers.common['access-token'] = ACCESS_TOKEN;
  }
  

  //console.dir(config);
  return config;
}, function (error) {
  // Do something with request error
  console.info("error: ");
  console.info(error);
  return Promise.reject(error);
});


axios.interceptors.response.use(function (response) {
  if (response.data.Code === "PleaseLoginFirst") {
    history.push({
          pathname: '/'
      })
  }
  return response
}, function (error) {
  // Do something with response error 
  alert("服务器出错，请稍后再试！");
  return Promise.reject(error)
})

axios.interceptors.response.use(
  config => {
    return config;
  },
  err => {
    return Promise.reject(err)
  }
);


export default axios;