import {
    DIRECTORY_GET,DIRECTORY_SAVE,
    RIGHTKEY_OPEN,RIGHTKEY_CLOSE,
    TREEDIRECTORY_GET,TREEDIRECTORY_SAVE,
    SELECTROWKEYS_CHANGE,UPDATE_ACTIONLIST,
    SWITCH_LOADING,CHANGE_DIRECTORYMENU,SEARCHDIRECTORY_GET,
    DATALIST_CHANGE,ALLSELECT_CHANGE,CURRENTEDIT_CONTENT,
    SORTDIRECTORY_SAVE,ADMINNAME_CHANGE,CUSTOMNAME_CHANGE,HOST_CHANGE,AUTH_CHANGE,SHORTCUTSOBJ_CHANGE,FEEDBACK_CHANGE,LANG_CHANGE} from './actionType.js';

export default (state , action) => {
  switch(action.type) {
    case DIRECTORY_GET: {
      return {
          ...state,
          tableLoading: true
      };
    }
    case SEARCHDIRECTORY_GET: {
        return {
            ...state,
            tableLoading: true
        };
      }
    
    case DIRECTORY_SAVE: {
        return {
            ...state,
            dataList: action.data,
            path: action.path,
            tableLoading: false,
            selectedRowKeys: action.selectedRowKeys,
            searchText: action.searchText,
            isSearchDirectory: action.isSearchDirectory,
            authPermission: action.authPermission
        }
    }

    case SORTDIRECTORY_SAVE: {
        return {
            ...state,
            dataList: action.data,
        }
    }
    case TREEDIRECTORY_GET: {
        return {
            ...state,
        };
      }
    case TREEDIRECTORY_SAVE: {
        return {
            ...state,
            treeDirectory: action.data,
            
        }
    }
    case RIGHTKEY_OPEN: {
        return {
            ...state,
            rightKeyVisble: true
        }
    }
    case RIGHTKEY_CLOSE: {
        return {
            ...state,
            rightKeyVisble: false
        }
    }
    case SELECTROWKEYS_CHANGE: {
        return {
            ...state,
            selectedRowKeys: action.data
        }
    }
    case UPDATE_ACTIONLIST: {
        return {
            ...state,
            actionList: action.data
        }
    }

    case SWITCH_LOADING: {
        return {
            ...state,
            switchloading: action.data
        }
    }

    case CHANGE_DIRECTORYMENU: {
        return {
            ...state,
            isSearchDirectory: action.obj.data,
            searchText: action.obj.text
        }
    }
    case DATALIST_CHANGE: {
        return {
            ...state,
           dataList: action.data,
        }
    }
    case ALLSELECT_CHANGE: {
        return {
            ...state,
            isAllSelect: action.data,
        }
    }
    case CURRENTEDIT_CONTENT: {
        return {
            ...state,
            currentEditContent: action.data
        }
    }
    case ADMINNAME_CHANGE: {
        return {
            ...state,
            adminName: action.data
        }
    }
    case CUSTOMNAME_CHANGE: {
        return {
            ...state,
            customName: action.data
        }
    }
    case HOST_CHANGE: {
        return {
            ...state,
            host: action.data
        }
    }
    case AUTH_CHANGE: {
        return {
            ...state,
            authPermission: action.data
        }
    }
    case SHORTCUTSOBJ_CHANGE: {
        return {
            ...state,
            shortCutsObj: action.data
        }
    }
    case FEEDBACK_CHANGE:{
        return {
            ...state,
            feedbackStatus: action.data
        }
    }
    case LANG_CHANGE:{
        return {
            ...state,
            language: action.data
        }
    }
    
    
    default: {
      return state;
    }
  }
}
