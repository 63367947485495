export const makeDirectory = '/make_directory';  //新建文件夹
 
export const deleteMultiple = '/delete_multiple'; //删除文件、文件夹

export const rename = '/rename';     //重命名

export const downloadMulFiles = '/download_multiple_files';    //文件文件夹下载

export const newFile = '/new_file';   //新建文件

export const upload = '/upload';   //上传文件

export const extract = '/extract';  //解压缩

export const compress = '/compress';  //压缩文件

export const search = '/search';   //搜索文件

export const getFileContents = '/get_file_contents';  //查看文件内容

export const putFileContents = '/put_file_contents';  //编辑文件内容

export const copy = '/copy';             //复制文件、文件夹

export const cut = '/cut';          //剪切文件、文件夹

export const treeDirectory = '/tree_directory';  //获取目录树