import axios from '../../utils/axios'
import * as url from './urlConfig'
import {message} from 'antd';


export const home = {
    deleteMultiple(param) {
      return axios.post(url.deleteMultiple, param)
        .then((response) => {
          let data = response;
          if (data.status === 200) {
            return data;
          }else{
            message.error("api error");
          }
         
        })
        .catch(function (error) {
          message.error(error)
        })
    },
    downloadMultiple(param) {
        return axios.get(url.downloadMulFiles, {
            params: param,
            // headers:{
            //     'Content-Type': 'application/json; application/octet-stream'
            // },
            // responseType: 'blob',
        }
       
        )
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      compress(param) {
        return axios.post(url.compress,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      extract(param) {
        return axios.post(url.extract,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      makeDirectory(param) {
        return axios.post(url.makeDirectory,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      newFile(param) {
        return axios.post(url.newFile,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      copy(param) {
        return axios.post(url.copy,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      cut(param) {
        return axios.post(url.cut,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      upload(param,data) {
        return axios.post(url.upload,param,data,{
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      rename(param,data) {
        return axios.post(url.rename,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      getFileContents(param,data) {
        return axios.post(url.getFileContents,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },

      putFileContents(param,data) {
        return axios.post(url.putFileContents,param)
          .then((response) => {
            let data = response;
            if (data.status === 200) {
              return data;
            }else{
              message.error("api error");
            }
           
          })
          .catch(function (error) {
            message.error(error)
          })
      },
  }