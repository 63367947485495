import React from 'react';

import { HashRouter, Route, Switch, } from 'react-router-dom';
import {connect} from 'react-redux';

import Layout from './components/Layout';

import Home from './page/Home';
import Login from './page/login'
import Default from './page/default/index';

import {IntlProvider, FormattedMessage} from 'react-intl';
import zh_CN from "./locale/zh_CN";
import en_US from "./locale/en_US";


let messages = {}
messages['en-us'] = en_US;
messages['zh-cn'] = zh_CN;

class App extends React.Component {
  render() {
    return (
      <IntlProvider locale={this.props.state.language} messages={messages[this.props.state.language]}>
      <HashRouter>
          <Switch>
            {/* <Route path="/" exact  component={Login} /> */}
            <Route path="/" exact component={Default} />   
            <Layout>
              <Switch>
                <Route path="/home" component={Home} />   

              </Switch>
            </Layout>
          </Switch>
      </HashRouter>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state,ownProps) {
  return {
      state: state
  }
}

export default connect(mapStateToProps)(App)
