import React from 'react'
import {connect} from 'react-redux'
import { Layout,Spin  } from 'antd';
import Header from '../Header'

import './index.less'

import { getLangMessage } from '../../utils/index'

const { Content } = Layout;



class SideBar extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const {switchloading} = this.props.state
    return (
      <Spin tip={getLangMessage("loading_text")} size="large" spinning={switchloading}>
      
    
      <Layout id="mainContent">
        <Layout>
        <Header></Header>
          <Content
            style={{
              background: '#fff',
              minHeight: 500,
              paddingBottom:100
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
      </Spin>
    );
  }
}

function mapStateToProps(state,ownProps) {
  return {
      state: state
  }
}

export default connect(mapStateToProps)(SideBar);