import {
    DIRECTORY_GET,DIRECTORY_SAVE,
    RIGHTKEY_OPEN,RIGHTKEY_CLOSE,TREEDIRECTORY_GET,
    TREEDIRECTORY_SAVE,SELECTROWKEYS_CHANGE,
    SWITCH_LOADING,CHANGE_DIRECTORYMENU,SEARCHDIRECTORY_GET,
    DATALIST_CHANGE,ALLSELECT_CHANGE,CURRENTEDIT_CONTENT,
    SORTDIRECTORY_SAVE,ADMINNAME_CHANGE,CUSTOMNAME_CHANGE,HOST_CHANGE,AUTH_CHANGE,SHORTCUTSOBJ_CHANGE,FEEDBACK_CHANGE, LANG_CHANGE} from './actionType.js';


export const getDirectory = (location) => ({
  type: DIRECTORY_GET,
  location:location
});

export const saveDirectory = (data) => (
    {
        type: DIRECTORY_SAVE,
        data: data,
    }
)

export const sortSaveDirectory = (data) => (
    {
        type: SORTDIRECTORY_SAVE,
        data: data,
    }
)

export const getTreeDirectory = () => ({
    type: TREEDIRECTORY_GET, 
  });

export const saveTreeDirectory = (data,path) => (
    {
        type: TREEDIRECTORY_SAVE,
        data: data,
        path:path,
    }
)

export const openRightKey = () => (
    {
        type: RIGHTKEY_OPEN,
      
    }
)

export const closeRightKey = () => (
    {
        type: RIGHTKEY_CLOSE,
    }
)

export const changeSelectKeys = (data) => (
    {
        type: SELECTROWKEYS_CHANGE,
        data:data
    }
)

export const changeDirectory = (obj) => (
    {
        type: CHANGE_DIRECTORYMENU,
        data: obj.data,
        text: obj.text,
    }
)

export const switchLoading = (data) => (
    {
        type: SWITCH_LOADING,
        data: data
    }
)

export const getSearchDirectory = (data) => ({
    type: SEARCHDIRECTORY_GET,
    data:data
})

export const datalistChange = (data) => ({
    type: DATALIST_CHANGE,
    data:data
})
export const allselectChange = (data) => ({
    type: ALLSELECT_CHANGE,
    data:data
})

export const saveEditContent = (data) => ({
    type: CURRENTEDIT_CONTENT,
    data: data
})

export const adminNameChange = (data) => ({
    type: ADMINNAME_CHANGE,
    data: data
})

export const customNameChange = (data) => ({
    type: CUSTOMNAME_CHANGE,
    data: data
})

export const hostChange = (data) => ({
    type: HOST_CHANGE,
    data: data
})
export const authChange = (data) => ({
    type: AUTH_CHANGE,
    data: data
})

export const shortCutsChange = (data) => ({
    type: SHORTCUTSOBJ_CHANGE,
    data: data
})
export const feedbackChange = (data) => ({
    type: FEEDBACK_CHANGE,
    data: data
})

export const langChange = (data) => ({
    type: LANG_CHANGE,
    data: data
})





