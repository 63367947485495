 import React, { PureComponent } from 'react';
 import { Form, Input, Button,message } from 'antd';
 import * as Actions from '../../store/action'
 import {connect} from 'react-redux'
 import {userLogin} from './service'
 import './index.less'

 class Login extends PureComponent {
     constructor(props){
         super(props);
         this.state =  {
            isVisible:false,
            submitLoading: false
         }
     }
     componentWillMount(){
         localStorage.setItem("sitename","");
         this.props.dispatch(Actions.adminNameChange(""))
     }

     componentDidMount(){
         this.setState({
             isVisible: true
         })
     }

     handleSubmit = e => {
        e.preventDefault();
       
        this.props.form.validateFields((err, values) => {
          if (!err) {
            this.setState({
                submitLoading: true
            })
            let params = {...values,connect_type:1};
            userLogin.login(params).then(res=> {
                this.setState({
                    submitLoading: false
                })
                if(res.data.Code === "Success"){
                     message.success("登录成功");
                     localStorage.setItem("access-token",res.data.Data['access-token']);
                     localStorage.setItem("host", values.host);
                     this.props.dispatch(Actions.hostChange(values.host))
                     let searchStr = encodeURIComponent('path=/&searchText=');
                     this.props.history.push({pathname:"/home",search:searchStr})
                }else{
                    message.error(res.data.Message)
                }
            })
           
          }
        });
      };


     render(){

         const { getFieldDecorator } = this.props.form;
         const {submitLoading} = this.state;
         return(
             <div className="login">
                 <div className="login_content">
                        <div className="title">在线FTP管理系统</div>
                        <p className="sub_tips">连接到您的FTP服务器并开始编辑您的文件</p>
                        <div className="form_input">
                            <Form onSubmit={this.handleSubmit}>
                            <Form.Item label="FTP服务器" className="formItem">
                            {getFieldDecorator('host', {
                                rules: [{ required: true, message: '请输入ftp服务器地址!' }],
                            })(
                                <Input
                                placeholder="地址"
                                />,
                            )}
                            </Form.Item>
                            <Form.Item label="端口" className="formItem">
                            {getFieldDecorator('port', 
                            {
                                initialValue:21,
                                rules: [{ required: true, message: '请输入端口号' }],
                            })(
                                <Input
                                style={{width:'120px'}}
                                type="text"
                                placeholder="端口号"
                                />,
                            )}
                            </Form.Item>
                            <Form.Item label="token" className="formItem">
                            {getFieldDecorator('token', 
                            {
                                initialValue:'d675b5f5c3fe9d5e9d0fc2e91c654a26',
                                rules: [{ required: true, message: '请输入端口号' }],
                            })(
                                <Input
                                style={{width:'120px'}}
                                type="text"
                                placeholder="token"
                                />,
                            )}
                            </Form.Item>
                            <Form.Item label="用户名" className="formItem">
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: '请输入用户名!' }],
                            })(
                                <Input
                                placeholder="用户名"
                                />,
                            )}
                            </Form.Item>
                            <Form.Item label="密码" className="formItem">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码!' }],
                            })(
                                <Input
                                type="password"
                                placeholder="密码"
                                />,
                            )}
                            </Form.Item>
                            <Form.Item>
                            <div className="login_btn">
                            <Button type="primary" loading={submitLoading} htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                            </div>

                            </Form.Item>
                            </Form>
                        </div> 
                       
                 </div>
             </div>
         )
     }
 }
 function mapStateToProps(state,ownProps) {
    return {
        state: state
    }
  }


 export default connect(mapStateToProps)(Form.create()(Login));
