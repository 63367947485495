export const DIRECTORY_GET = 'DIRECTORY/GET';   //获取目录列表
export const DIRECTORY_SAVE = 'DIRECTORY/SAVE'; //保存目录列表

export const RIGHTKEY_OPEN = 'RIGHTKEY/OPEN';   //右键菜单打开
export const RIGHTKEY_CLOSE = 'RIGHTKEY/CLOSE'; //右键菜单关闭

export const TREEDIRECTORY_GET = 'TREEDIRECTORY/GET';  //获取全部目录树
export const TREEDIRECTORY_SAVE = 'TREEDIRECTORY/SAVE';  //保存全部目录树

export const SELECTROWKEYS_CHANGE = 'SELECTROWKEYS/CHANGE';  //选中目录

export const UPDATE_ACTIONLIST = 'UPDATA/ACTIONLIST';           //更新数据

export const SWITCH_LOADING = 'SWITCH/LOADING';    //发送请求的loading切换

export const CHANGE_DIRECTORYMENU = 'CHANGE/DIRECTORYMENU';     //确定是搜索关键字目录还是正常目录

export const SEARCHDIRECTORY_GET = 'SEARCHDIRECTORY/GET';    //搜索关键字目录 

export const DATALIST_CHANGE = 'DATALIST/CHANGE';      //数据的改变存储

export const ALLSELECT_CHANGE = 'ALLSELECT/CHANGE';   //另一种模式的全选

export const CURRENTEDIT_CONTENT = 'CURRENTEDIT/CONTENT';  //当前编辑的内容

export const SORTDIRECTORY_SAVE = 'SORTDIRECTORY/SAVE';   //排序存储目录数据

export const ADMINNAME_CHANGE = 'ADMINNAME/CHANGE';     //管理名称存储

export const HOST_CHANGE = 'HOST/CHANGE';     //HOST存储

export const AUTH_CHANGE = 'AUTH/CHANGE';       //权限列表查询

export const SHORTCUTSOBJ_CHANGE = 'SHORTCUTSOBJ/CHANGE';   //快捷键存储内容

export const FEEDBACK_CHANGE = 'FEEDBACK/CHANGE';     

export const LANG_CHANGE = 'LANG/CHANGE';  //切换语言

export const CUSTOMNAME_CHANGE = 'CUSTOMNAME/CHANGE';  //自定义名称存储