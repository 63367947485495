import React, { PureComponent } from 'react';
import './index.less';

class Default extends PureComponent {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        return(
            <div className="default_page">

            </div>
        )
    }
}

export default Default;